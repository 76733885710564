exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-checkoutdetail-js": () => import("./../../../src/pages/checkoutdetail.js" /* webpackChunkName: "component---src-pages-checkoutdetail-js" */),
  "component---src-pages-checkouterror-js": () => import("./../../../src/pages/checkouterror.js" /* webpackChunkName: "component---src-pages-checkouterror-js" */),
  "component---src-pages-checkoutsuccess-js": () => import("./../../../src/pages/checkoutsuccess.js" /* webpackChunkName: "component---src-pages-checkoutsuccess-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-coursedetails-js": () => import("./../../../src/pages/coursedetails.js" /* webpackChunkName: "component---src-pages-coursedetails-js" */),
  "component---src-pages-forgotpassword-js": () => import("./../../../src/pages/forgotpassword.js" /* webpackChunkName: "component---src-pages-forgotpassword-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-refunds-js": () => import("./../../../src/pages/refunds.js" /* webpackChunkName: "component---src-pages-refunds-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-usercheckoutdetail-js": () => import("./../../../src/pages/usercheckoutdetail.js" /* webpackChunkName: "component---src-pages-usercheckoutdetail-js" */)
}


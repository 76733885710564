import fetch from "isomorphic-fetch";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getCookie } from "../utils/cookie";

const httpLink = createHttpLink({
  uri: process.env.GATSBY_GRAPHQL_ENDPOINT,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookies if it exists
  const token = getCookie("ninja_session");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-access-token": token,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          dynoesConnection: {
            keyArgs: false,
            merge(existing, incoming) {
              let launches = [];
              if (existing && existing.launches) {
                launches = launches.concat(existing.launches);
              }
              if (incoming && incoming.launches) {
                launches = launches.concat(incoming.launches);
              }
              return {
                ...incoming,
                launches,
              };
            },
          },
        },
      },
    },
  }),
});

export default client;
